<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增等级</el-button>
			</el-row>
			<!-- 等级表单 -->
      <div>
        <ServiceTypeScroll :list="typeList" @handleServiceTypeChange="typeChange"></ServiceTypeScroll>
        <el-table border :data="levelData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
          <el-table-column prop="grade_name" label="等级"></el-table-column>
          <el-table-column prop="menpai_name" label="有无门派"></el-table-column>
          <el-table-column prop="min_yeji_name" label="最低业绩"></el-table-column>
          <el-table-column prop="jiazhong_bfb_name" label="加钟率(%)"></el-table-column>
          <el-table-column prop="online_hour_name" label="在线时长(H)"></el-table-column>
          <el-table-column prop="jifen_num" label="积分"></el-table-column>
          <el-table-column prop="fencheng_bfb" label="分成比例(%)"></el-table-column>
          <el-table-column label="操作" width="160px">
            <template slot-scope="scope">
              <el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
              <el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="36%" top="3vh" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
          <el-col :span="22">
            <el-form-item label="服务类型:" prop="type_id">
              <el-select v-model="addlevelForm.type_id" placeholder="请选择服务类型" clearable style="width: 100%;">
                <el-option
                    v-for="item in typeList"
                    :key="item.type_id"
                    :label="item.type_name"
                    :value="item.type_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="等级名称" prop="grade_name">
						<el-input v-model="addlevelForm.grade_name" maxlength="10" placeholder="请输入等级名称"></el-input>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="有无门派" prop="is_menpai" style="text-align: left;">
						<el-radio-group v-model="addlevelForm.is_menpai">
							<el-radio v-for="(item,index) in menpai_options" :label="index">{{item}}</el-radio>
						</el-radio-group>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="最低业绩" prop="min_yeji">
						<el-input v-model.number="addlevelForm.min_yeji" placeholder="请输入最低业绩" oninput="if(value<0)value=0"></el-input>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="加钟率(%)" prop="jiazhong_bfb">
						<el-input v-model.number="addlevelForm.jiazhong_bfb" placeholder="请输入加钟率" oninput="if(value<0)value=0; else if(value>100)value=100"></el-input>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="在线时长(H)" prop="online_hour">
						<el-input v-model.number="addlevelForm.online_hour" placeholder="请输入在线时长" oninput="if(value<0)value=0"></el-input>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="积分" prop="jifen_num">
						<el-input v-model.number="addlevelForm.jifen_num" placeholder="请输入积分" oninput="if(value<0)value=0"></el-input>
					</el-form-item>
          </el-col>
          <el-col :span="22">
					<el-form-item label="分成比例(%)" prop="fencheng_bfb">
						<el-input v-model.number="addlevelForm.fencheng_bfb" placeholder="请输入分成比例" oninput="if(value<0)value=0; else if(value>100)value=100"></el-input>
					</el-form-item>
          </el-col>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	import {serviceTypeAll} from "@/api/request/service";

  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	import ServiceTypeScroll from '@/components/Page/ServiceTypeScroll.vue'
	export default {
		components: {
			Pagination,ServiceTypeScroll
		},
		data() {
			return {
				title: "新增等级",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
          type_id: 0,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
          type_id: '',
					grade_name: '',
					is_menpai: 0,
					min_yeji: '',
					jiazhong_bfb: '',
					online_hour: '',
					jifen_num: '',
					fencheng_bfb: '',
				},
				addlevelRules: {
          type_id: [{required: true, message: "请选择服务类型", trigger: "blur"}, ],
					grade_name: [{
						required: true,
						message: "请输入等级",
						trigger: "blur"
					}, ],
					jifen_num: [{
							required: true,
							message: "请输入积分",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					fencheng_bfb: [{
							required: true,
							message: "请输入分成比例",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
				},
				levelData: [],
				menpai_options: ["无","有"],
        typeList:[],
			}
		},
		created() {
      that = this;
      this.getServiceType();
		},
		methods: {
			getJishiGrade() {
				var url = 'jishi_grade/grade_list';
				let params = {
					grade_name: this.queryInfo.query,
          type_id: this.queryInfo.type_id,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
			//新增等级
			addLevel() {
				this.title = "新增等级";
				this.addlevelForm = {
					type_id: this.queryInfo.type_id,
					grade_name: '',
					is_menpai: 0,
					min_yeji: '',
					jiazhong_bfb: '',
					online_hour: '',
					jifen_num: '',
					fencheng_bfb: '',
				};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'jishi_grade/addOrUpdate';
							let params = this.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getJishiGrade();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},1000);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑等级";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'jishi_grade/deleteGrade';
					let params = {
						grade_id: item.grade_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getJishiGrade();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getJishiGrade();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getJishiGrade();
			},
      typeChange(e) {
        this.queryInfo.type_id = e
        this.getJishiGrade();
      }
		},
	}
</script>

<style lang="scss" scoped>
	@import './Technician_level.scss';
</style>